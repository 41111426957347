import {
  FinalPoints,
  LoyaltyData,
  MockLoyaltyData
} from "@/interfaces/loyaltyProgram";
import { ModalConfirm } from "@/interfaces/notification";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./AddRemovePoints.template.vue";

interface ActionItems {
  value: string;
  text: string;
}

@Component({
  mixins: [Template]
})
export default class AddRemovePoints extends Vue {
  @Prop({ required: true })
  public modalData!: ModalConfirm;
  public displayData: LoyaltyData = MockLoyaltyData;
  public actionItems!: ActionItems[];
  public action: string = "Add";
  public points: string = "";
  public isFormValid = false;
  public close() {
    this.$emit("reject");
  }

  public created() {
    this.displayData = { ...this.modalData.body };
    this.actionItems = [
      { value: "Add", text: this.$t("ADD").toString() },
      { value: "Remove", text: this.$t("REMOVE").toString() }
    ];
  }

  public async applyChanges() {
    const finalPoints: FinalPoints = {
      points: 0
    };
    if (this.action === "Remove") {
      finalPoints.points = +this.points * -1;
    } else {
      finalPoints.points = +this.points;
    }
    const resp = await loyaltyProgramService.modifyLoyaltyPoints(
      this.displayData.customerUID,
      finalPoints
    );
    if (resp) {
      this.$emit("resolve");
    }
  }

  public cancel() {
    this.$emit("reject");
  }

  get pointsWorth() {
    if (isNaN(+this.points)) {
      return "";
    }
    return (
      +this.points /
      this.displayData.points!.loyalty_point!.redemption_value_per_points!
    ).toFixed(2);
  }
  get usablePoints() {
    this.isFormValid = false;

    if (isNaN(+this.points)) {
      return "";
    } else if (
      this.action === "Remove" &&
      +this.points <=
        this.displayData.points!.loyalty_redemption!.loyalty_points! &&
      +this.points > 0
    ) {
      this.isFormValid = true;
      return (
        this.displayData.points!.loyalty_redemption!.loyalty_points! -
        +this.points
      );
    } else if (this.action === "Add" && +this.points > 0) {
      this.isFormValid = true;
      return (
        this.displayData.points!.loyalty_redemption!.loyalty_points! +
        +this.points
      );
    }
    return "";
  }

  get dollarWorth() {
    this.isFormValid = false;
    if (isNaN(+this.points)) {
      return "";
    } else if (this.usablePoints !== "" && this.usablePoints >= 0) {
      this.isFormValid = true;
      return (
        +this.usablePoints /
        this.displayData.points!.loyalty_point!.redemption_value_per_points!
      ).toFixed(2);
    }
  }
}
