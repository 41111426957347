import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { Country, State } from "@/interfaces/geographical";
import { RetailSettings } from "@/interfaces/retailSettings";
import { customerService } from "@/services/customer.service";
import { geographicalService } from "@/services/geographical.service";
import { BooleanCheck } from "@/types/types";
import debounce from "lodash/debounce";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./MoreInfo.template.vue";
/**
 * product basic info form component
 */
@Component({
  mixins: [Template]
})
export default class MoreInfoComponent extends Vue {
  @Prop({ required: true })
  public disableEditMode!: boolean;
  public get genderItems(): Array<{ value: string; text: string }> {
    return [
      { value: "FEMALE", text: this.$t("female").toString() },
      { value: "MALE", text: this.$t("male").toString() }
    ];
  }
  public get getStates() {
    return this.states;
  }

  get customerItems() {
    // this is to keep a copy of the former customer that referred
    if (this.refererCustomer && this.customer.referred_by) {
      return [this.refererCustomer, ...this.customers];
    } else {
      return this.customers;
    }
  }
  @Inject("$validator") public $validator!: Validator;
  public countries: Country[] = [];
  public states: State[] = [];
  public loaded: boolean = false;
  public customers: Customer[] = [];
  public customersLoading: boolean = false;
  public searchCustomersField: string = "";
  public isPharmacyEnabled = false;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  public refererCustomer: Customer | null = null;
  public dCustomerSearch = debounce(async (scope: any, val: string) => {
    if ((!val || val.length > 2) && !scope.customer.referred_by) {
      scope.customersLoading = true;
      const response = await customerService.get({
        "q[first_name_or_last_name_contains]": val
      });
      scope.customers = response;
      scope.customersLoading = false;
    }
  }, 500);
  @Prop({ default: false })
  public validated!: boolean;

  @Prop({ required: true })
  protected customer!: Customer;

  @Watch("searchCustomersField")
  public searchCustomers(val: string) {
    this.dCustomerSearch(this, val);
  }

  // get the initial customer just once
  @Watch("customer.referred_by", { deep: true })
  public async searchReferer() {
    if (this.customer.referred_by) {
      const referer = await customerService.findById(this.customer.referred_by);
      this.customers = [referer!];
      this.refererCustomer = referer;
    }
  }

  @Watch("customer.country", { deep: true })
  public async countryChanged() {
    if (!this.states.length) {
      this.onSelectedCountry();
    }
  }

  public clearSearch(value: number) {
    if (!value) {
      this.searchCustomers("");
    }
  }

  public async onSelectedCountry() {
    this.states = await geographicalService.getStates(
      String(this.customer.country)
    );
    if (
      this.$route.params.newCustomer &&
      this.$route.params.newCustomer !== undefined
    ) {
      setTimeout(() => {
        const newCustomer = JSON.parse(this.$route.params.newCustomer);

        const stateIndex = this.states.findIndex(
          state => String(state.code) === String(newCustomer.state)
        );
        if (stateIndex !== -1) {
          this.customer.state = this.states[stateIndex].name;
        }
      }, 200);
    } else {
      if (String(this.customer.state).length === 2) {
        const selectedStateIndex = this.states.findIndex(
          state => state.code === String(this.customer.state)
        );
        if (selectedStateIndex !== -1) {
          this.customer.state = this.states[selectedStateIndex].name;
        }
      } else {
        const stateName = this.states.filter(
          state => state.id === this.customer.state
        );
        this.customer.state =
          stateName && stateName.length
            ? stateName[0].name
            : this.customer.state;
      }
    }
  }

  public async validate(): Promise<boolean> {
    const moreInfoValidation = await this.$validator.validateAll("moreInfo");
    return moreInfoValidation;
  }

  public mounted() {
    if (this.validated) {
      this.validate();
    }
    if (
      this.$route.params.newCustomer &&
      this.$route.params.newCustomer !== undefined
    ) {
      const newCustomer = JSON.parse(this.$route.params.newCustomer);

      this.customer.gender = newCustomer.gender === "1" ? "MALE" : "FEMALE";
    }

    this.isPharmacyEnabled = !!this.currentRetailSettings.pharmacy_mode_enabled;
    this.getCountries();
    this.searchReferer();
  }

  private async getCountries() {
    this.countries = await geographicalService.getCountries();
    if (this.countries && this.countries.length) {
      if (
        this.$route.params.newCustomer &&
        this.$route.params.newCustomer !== undefined
      ) {
        setTimeout(() => {
          const newCustomer = JSON.parse(this.$route.params.newCustomer);

          const countryIndex = this.countries.findIndex(
            country =>
              String(country.iso_alpha3) === String(newCustomer.country)
          );
          if (countryIndex !== -1) {
            this.customer.country = this.countries[countryIndex].name;
          }
        }, 200);
      } else {
        const countryName = this.countries.filter(
          country => country.id === this.customer.country
        );
        this.customer.country =
          countryName && countryName.length
            ? countryName[0].name
            : this.customer.country;
      }
      if (!!this.customer.country) {
        this.onSelectedCountry();
      }
    }
  }
}
