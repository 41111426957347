import { EventBus } from "@/event-bus";
import { RuleConfig } from "@/interfaces/limits";
import Vue from "vue";

import { Component, Prop } from "vue-property-decorator";

import Template from "./ValidateMedicalTextfield.template.vue";

@Component({
  mixins: [Template]
})
export default class ValidateMedicalTextfield extends Vue {
  @Prop({ required: true }) public limit!: RuleConfig;
  @Prop({ required: false }) public dailyAmount!: boolean;
  @Prop({ required: true }) public index!: number;
  @Prop({ default: false }) public disabled!: boolean;

  public errorMessage: boolean = false;

  public validateLimitValue: number = 1;

  public onChangeAmount() {
    this.$emit("changeAmount", this.limit);
  }

  public validateInput(value: string) {
    if (Number(value) === 0) {
      if (this.dailyAmount) {
        this.limit.daily_amount = Number(value);
      } else {
        this.limit.amount = null;
      }
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
    }
    if (value && !/^[0-9]{0,7}(?:\.[0-9]{1,3})?$/.test(value)) {
      this.validateLimitValue += 1;
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
    } else if (Number(value) !== 0) {
      this.errorMessage = false;
      if (this.dailyAmount) {
        this.limit.daily_amount = Number(value);
      } else {
        this.limit.amount = Number(value);
      }
    }
  }

  public validateInputCountables(value: string) {
    if (Number(value) === 0) {
      this.limit.amount = null;
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
    }
    if (value && !/^[0-9]{0,7}?$/.test(value)) {
      this.validateLimitValue += 1;
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
    } else if (Number(value) !== 0) {
      this.errorMessage = false;
      if (this.dailyAmount) {
        this.limit.daily_amount = Number(value);
      } else {
        this.limit.amount = Number(value);
      }
    }
  }

  public validateCountableDecimal(
    value: KeyboardEvent,
    countableUnits: boolean
  ) {
    if (!/^[0-9]?\.?$/.test(value.key)) {
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
      value.preventDefault();
    }

    if (countableUnits && value.key === ".") {
      this.errorMessage = true;
      setTimeout(() => {
        this.errorMessage = false;
      }, 2500);
      value.preventDefault();
    }
  }

  public mounted() {
    EventBus.$on("onChangeCustomerLimit", () => {
      this.$validator.reset();
    });
    EventBus.$on("onCheckingCustomerLimit", async () => {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        this.errorMessage = true;
        setTimeout(() => {
          this.errorMessage = false;
        }, 2500);
      }
    });
  }
}
