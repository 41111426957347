import TableProductsComponent from "@/components/sharedComponents/tableProducts/TableProductsComponent";
import { Order } from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { SalesPreviousCustomerTableMetadata } from "@/metadata/order";
import { salesHistoryFieldsFunction } from "@/metadata/salesHistory";
import { metrcEnabled } from "@/router.utils";
import { messagesService } from "@/services/messages.service";
import { orderService } from "@/services/order.service";
import { usersService } from "@/services/user.service";
import {
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./CustomerHistory.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableProductsComponent,
    TableComponent
  }
})
export default class CustomerHistoryComponent extends Vue {
  public pagination: TablePagination = { ...TablePaginationDefault };
  public loading: boolean = false;
  public ordersData: Order[] = [];
  public hasMetrc = metrcEnabled();
  public headers: TableHeader[] = [];
  public fieldsToShow: Array<{ [key: string]: string }> = [];
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public tableData: Order[] = [];

  public parsedOrders() {
    this.tableData = this.ordersData.map(order => ({
      ...order,
      metrc_reciept_number: `${order.status}`,
      total: order.total,
      sub_total: order.sub_total,
      tax_total: order.tax_total,
      line_order_discounts: order.line_order_discounts,
      order_items:
        order.order_items &&
        order.order_items.map(item => ({
          ...item,
          available: +item.available!.toFixed(2),
          price_final: item.price_final,
          quantityFormated: `${item.quantity}${item.product_info &&
            item.product_info.product_unit}`
        }))
    }));
  }

  protected async mounted() {
    this.headers = SalesPreviousCustomerTableMetadata(this.hasMetrc);
    this.fieldsToShow = salesHistoryFieldsFunction(
      this.retailSettings.stock_identification_type_in_pos
    );
    await this.loadData(this.pagination);
  }
  private async loadData(pagination: TablePagination) {
    this.loading = true;
    try {
      const response = await orderService.getOrdersPaidOfCustomer(
        this.$route.params.id ? this.$route.params.id : "",
        pagination
      );
      this.pagination = await orderService.getPagination(true);
      delete this.pagination.changeItemsPerPage;
      this.ordersData = response.data.data;
      const usersIds = [];
      for (const order of this.ordersData) {
        if (order.user_id) {
          usersIds.push(order.user_id);
        }
      }
      const users = await usersService.getUsers(usersIds);
      const usersMap = new Map();
      for (const user of users) {
        usersMap.set(user.id, user);
      }
      if (this.ordersData[0]) {
        this.ordersData[0].order_items!.map(order => {
          if (
            this.hasBioTrackTraceIntegrations &&
            this.bioTrackTraceEnabled &&
            order.biotrack_traceability_id !== null
          ) {
            order.batch_barcode_uid = order.biotrack_traceability_id;
          }
        });
      }
      for (const order of this.ordersData) {
        if (order.user_id) {
          order.user = usersMap.get(order.user_id);
        }
      }
      this.parsedOrders();
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.loading = false;
    }
  }
}
