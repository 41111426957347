import { JournalNotes } from "@/interfaces/journalNotes";
import { TableLimitComponent } from "helix-vue-components";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Template from "./CustomerNotes.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableLimitComponent
  }
})
export default class CustomerNotesDetailsComponent extends Vue {
  @Prop({ default: () => [] })
  public item!: JournalNotes;
}
