import { Customer } from "@/interfaces/customer";
import find from "lodash/find";
import omit from "lodash/omit";

export enum profileType {
  PATIENT = "PATIENT",
  CAREGIVER = "CAREGIVER"
}
export interface Field {
  id: string;
  name: string;
  scope: string;
  flags: FieldFlags;
  isRequired: boolean;
  componentInstance: FieldValue;
}
interface FieldValue {
  internalValue: string;
}
interface FieldFlags {
  changed: boolean;
  required: boolean;
}

export const clearModel = (model: Customer): Customer => {
  const omitProp = [
    "serving_number",
    "applicable_limits",
    "limits",
    "note",
    "status_at",
    "imageToDisplay",
    "isCaregiver",
    "isPatient",
    "patientsToDelete",
    "patientsToSave",
    "patient",
    "caregiver"
  ];
  if (model.profiles) {
    if (!model.profiles.length) {
      omitProp.push("profiles");
    }
  }
  return omit(model, omitProp) as Customer;
};
