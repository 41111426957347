import { policyList } from "@/enums/permissions";
import {
  Attachment,
  AttachmentLocation,
  Customer
} from "@/interfaces/customer";
import { Location } from "@/interfaces/location";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { customerService } from "@/services/customer.service";
import { messagesService } from "@/services/messages.service";
import { BooleanCheck } from "@/types/types";
import {
  TableAction,
  TableComponent,
  TableFieldType,
  TableHeader,
  TableSuccessModalResponse
} from "helix-vue-components";
import dropWhile from "lodash/dropWhile";
import find from "lodash/find";
import union from "lodash/union";
import xorBy from "lodash/xorBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./DocumentsUpload.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export default class DocumentsUploadComponent extends Vue {
  public get fullFilelist(): Attachment[] {
    this.customer.documents = union(
      this.documentsToSave,
      this.documentsToDelete
    );

    return this.customer.documents;
  }
  @Prop({ required: true })
  public customer!: Customer;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public loading: boolean = false;
  @Prop({ required: true })
  public disableEditMode!: boolean;
  @Prop()
  public documentsToSaveModel!: Attachment[];
  public fileOptions = {
    multiple: false,
    accept: ".pdf"
  };
  public invalidFileName: boolean = false;
  public fileHeaders: TableHeader[] = [
    {
      label: "File Name",
      value: "name",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.string
    },
    {
      label: "Location",
      value: "location.name",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.string
    },
    {
      label: "Date",
      value: "lastModifiedDate",
      class: "tdt__headers__fieldLong",
      fieldComponent: TableFieldType.fnsDate
    }
  ];

  public fileActions: TableAction[] = [
    {
      icon: "fas fa-eye",
      action: (...arg: File[]) => {
        // @ts-ignore
        if (!!!arg[0].url) {
          const reader = new FileReader();
          reader.readAsDataURL(arg[0]);
          reader.onloadend = () => {
            // @ts-ignore
            this.openPdf(reader.result, "");
          };
        } else {
          // @ts-ignore
          this.openPdf(arg[0].url, "", true);
        }
      }
    },
    {
      icon: "fal fa-trash-alt",
      modalActions: {
        modalNumber: 1,
        modalQuestion: i18n.t("customer.delete_confirmation").toString(),
        modalSuccessText: "yes",
        modalSuccessAction: (arg: TableSuccessModalResponse) => {
          const document = find(this.documentsToSave, {
            name: arg.item.name,
            size: arg.item.size
          });
          if (!!document) {
            this.removeDocuments(arg.item as Attachment);
          } else {
            arg.item._destroy = true;
            this.documentsToDelete.push(arg.item as Attachment);
          }
          arg.unselectModal();
        },
        modalCancelText: "no"
      }
    }
  ];
  private documentsToSave: Attachment[] = [];
  private documentsSaved: Attachment[] = [];
  private documentsToDelete: Attachment[] = [];

  public onFileChange(e: any) {
    const files = e.target.files[0];
    files.location = {
      account_id: this.currentLocation.account_id,
      id: this.currentLocation.id,
      name: this.currentLocation.name
    };
    // getting file extension
    const fileExtension = files.name.match(/\.([^\.]+)$/)[1];
    // getting file size in "mega bytes" by dividing with 1000000
    const fileSize = files.size / 1000000;
    // only " alphanumeric,_ , - , ." characters are allowed in file name
    if (/([^a-zA-Z0-9_.\-])/.test(files.name)) {
      this.invalidFileName = true;
    } else {
      this.invalidFileName = false;
    }

    const invalidDocMsg: string | string[] = [];
    if (
      !store.getters["AuthModule/fileDocumentExtention"].includes(fileExtension)
    ) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_extention", {
            fileExtention: store.getters["AuthModule/fileDocumentExtention"]
          })
        )
      );
    }
    if (fileSize > store.getters["AuthModule/fileUploadLimit"]) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_size", {
            fileSize: store.getters["AuthModule/fileUploadLimit"]
          })
        )
      );
    }
    if (this.invalidFileName) {
      invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
    }
    if (invalidDocMsg.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        invalidDocMsg,
        "error"
      );
    } else {
      this.documentsToSave.push(files);
    }
  }

  public async loaded() {
    if (!!this.documentsToSave) {
      this.loading = true;
      this.documentsToSave = this.documentsToSaveModel;
      this.loading = false;
    }
  }

  public mounted() {
    this.loaded();
  }

  private openPdf(myBase64string: string, noSaved: boolean = false) {
    let objbuilder = "";
    objbuilder += '<iframe width="100%" height="100%" src="';
    objbuilder += myBase64string;
    objbuilder += '" type="application/pdf"></iframe>';
    objbuilder += "";
    let body =
      '<html><link rel="icon" href="/favicon.ico"><title>BioTrack</title>';
    body +=
      '<body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">';
    body += objbuilder;
    body += "</body></html>";
    const win = window.open(noSaved ? myBase64string : "#", "_blank");
    if (!noSaved) {
      win!.document.write(body);
    }
  }

  private removeDocuments(file: Attachment) {
    this.documentsToSave = dropWhile(this.documentsToSave, {
      name: file.name,
      size: file.size
    });
  }
}
