import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { MemberLevel } from "@/interfaces/memberLevel";
import { BalanceItem } from "@/interfaces/order";
import { memberLevelService } from "@/services/memberLevel.service";
import { orderService } from "@/services/order.service";
import { BooleanCheck } from "@/types/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PurchasingDetails.template.vue";

@Component({
  mixins: [Template],
  components: {}
})
export default class PurchasingDetailsComponent extends Vue {
  public memberLevel: MemberLevel[] = [];
  public balance: BalanceItem | null = null;
  @Prop({ required: true })
  public disableEditMode!: boolean;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Prop({ required: true })
  protected customer!: Customer;

  public async mounted() {
    if (this.currentCustomer && this.currentCustomer.customer_id) {
      this.balance = await orderService.getBalanceCustomer(
        this.currentCustomer.customer_id
      );
    }
    this.getMemberLevel();
  }

  protected async getMemberLevel() {
    const noneMemberLevel: MemberLevel = {
      id: null,
      code: null,
      name: this.$t("inventory.none").toString()
    };
    this.memberLevel = await memberLevelService.getMemberLevels();
    this.memberLevel = [noneMemberLevel, ...this.memberLevel];
    return;
  }
}
