import ModalPatientsComponent from "@/components/retail/customers/customersForm/medicalCaregiver/modalPatients/ModalPatients.component";
import ItemAggregatorComponent from "@/components/retail/discountManager/discountManagerAdd/build-blocks/ItemAggregator/ItemAggregator.component";
import TableProductsComponent from "@/components/sharedComponents/tableProducts/TableProductsComponent";
import { policyList } from "@/enums/permissions";
import { EventBus } from "@/event-bus";
import {
  Customer,
  CustomerLimitConfig,
  CustomerModel,
  defaultProfile,
  Profile
} from "@/interfaces/customer";
import { Doctor } from "@/interfaces/doctor";
import { customerLimits, RuleConfig } from "@/interfaces/limits";
import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import {
  CaregiverTableMetadata,
  PatientsTableMetadata
} from "@/metadata/customer";
import {
  itemsOrder,
  SalesHistoryCustomerTableMetadata
} from "@/metadata/order";
import { doctorsTrackingEnabled } from "@/router.utils";
import { customerService } from "@/services/customer.service";
import { limitsService } from "@/services/limits.service";
import { orderService } from "@/services/order.service";
import { BooleanCheck, Callback } from "@/types/types";
import {
  HelixDatePickerComponent,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TableSuccessModalResponse
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import union from "lodash/union";
import xorBy from "lodash/xorBy";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./MedicalCaregiver.template.vue";
import ValidateMedicalTextfield from "./ValidateMedicalTextfield.component";

const namespace: string = "OrderModule";
enum profileType {
  PATIENT = "PATIENT",
  CAREGIVER = "CAREGIVER"
}
interface Options {
  value: string;
  text: string;
}
@Component({
  mixins: [Template],
  components: {
    TableProductsComponent,
    TableComponent,
    "item-aggregator": ItemAggregatorComponent,
    HelixDatePickerComponent,
    ValidateMedicalTextfield
  }
})
export default class MedicalCaregiverComponent extends Vue {
  public get fullListPatients(): Customer[] {
    this.loadPatientsAssignment();
    this.customer.patients = {
      attach: this.arrayToSave.map(patient => patient.customer_id!),
      detach: this.arrayToDelete.map(patient => patient.customer_id!),
      assigned:
        (this.patientsAssignment &&
          this.patientsAssignment.map(patient => patient.customer_id!)) ||
        []
    };
    return xorBy(
      union(this.arrayToSave, this.patientsAssignment),
      this.arrayToDelete,
      "customer_id"
    );
  }

  public get doctorTrackingCondition(): boolean {
    return doctorsTrackingEnabled(); // to get doctor tracking enable condition
  }
  public startingTimeFormated: string = "";
  public endingTimeFormated: string = "";
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("orders", { namespace })
  public orders!: boolean;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  public patientsPagination: TablePagination = customerService.getResetedPagination();
  public caregiversPagination: TablePagination = customerService.getResetedPagination();
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public rowActions: TableAction[] = [];
  public customersLoading = false;
  public updateCustomerLimit!: CustomerLimitConfig | null;
  public headers: TableHeader[] = SalesHistoryCustomerTableMetadata;
  public headersPatients: TableHeader[] = PatientsTableMetadata;
  public headerCaregiver: TableHeader[] = CaregiverTableMetadata;
  public newPatient: Profile[] = [];
  public keyChangeCustomer: number = 0;
  public loadingDoctors = false;
  public extendedLimitsToggle: 0 | 1 = 0;
  public locationLimitType!: string;
  public paginate = orderService.paginationAction();

  @Prop({ required: true })
  public customer!: CustomerModel;
  @Prop({ required: true })
  public isMedical!: boolean;
  @Prop({ default: false })
  public validated!: boolean;
  @Prop({ required: true })
  public disableEditMode!: boolean;
  public conditions: Array<{ name: string; value: string }> = [];
  public doctors: Doctor[] = [];
  public caregiverAssignment: Customer[] = [];
  public patientsAssignment: Customer[] | null = null;
  public arrayToSave: Customer[] = [];
  public arrayToDelete: Customer[] = [];
  public loadingPatients: boolean = false;
  public newConditions: string[] = [];
  public limitStrategy: string = "";
  public tempLicense: boolean = false;
  public fieldsToShow: Array<{ [key: string]: string }> = [];
  public customerLimits!: customerLimits | null;
  public ruleUnits: Options[] = [];
  public extendedLimitShow: boolean = false;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  public limitsExists!: boolean;
  @Action("loadOrderPaidOfCaregiver", { namespace })
  protected loadOrders!: Callback;
  @Action("setPagination", { namespace })
  protected setPagination!: Callback;
  private arrayTemporal: Customer[] = [];
  @Watch("customer")
  public onCustomerChange() {
    const newDocID = this.$route.query.newDocId;
    if (newDocID && newDocID !== "noDoctorAdded") {
      this.customer!.patient!.doctor_id = +newDocID;
      this.customer.isPatient = true;
    }
  }

  public async created() {
    this.$validator.extend("medicalValidation", () => {
      return (
        !this.isMedical || this.customer.isPatient || this.customer.isCaregiver
      );
    });

    this.customer.caregiverAssignment = [];
    this.customer.patientsAssignment = [];
    this.newConditions =
      (this.customer.conditions &&
        this.customer.conditions.map(item => String(item.condition_id))) ||
      [];
    this.conditions = [];
    if (
      this.customer.isPatient &&
      !this.caregiverAssignment.length &&
      this.customer.customer_id
    ) {
      this.caregiverAssignment = await customerService.getCaregivers(
        this.customer.customer_id
      );
      this.caregiversPagination = await customerService.getPagination();
    }
  }

  public changePagination(pagination: TablePagination) {
    this.paginate(
      {
        currentPage: pagination.currentPage,
        itemsPerPage: pagination.itemsPerPage,
        dispatchAction: `${namespace}/loadOrderPaidOfCaregiver`
      },
      this.customer.customer_id
    );
  }

  public async changePatientsPagination(pagination: TablePagination) {
    if (!this.customer.customer_id) {
      return;
    }
    this.loadingPatients = true;
    this.patientsAssignment = await customerService.findPatients(
      this.customer.customer_id,
      pagination
    );
    this.patientsPagination = await customerService.getPagination();
    this.loadingPatients = false;
  }

  public async changeCaregiversPagination(pagination: TablePagination) {
    if (!this.customer.customer_id) {
      return;
    }
    this.caregiverAssignment = await customerService.getCaregivers(
      this.customer.customer_id,
      pagination
    );
    this.caregiversPagination = await customerService.getPagination();
  }

  public setDatePatient(date: string) {
    this.customer.patient!.medical_card_expiration = date;
  }

  public async newProfilePatient() {
    if (
      this.customer.isPatient &&
      !this.caregiverAssignment.length &&
      this.customer.customer_id
    ) {
      this.caregiverAssignment = await customerService.getCaregivers(
        this.customer.customer_id
      );
      this.caregiversPagination = await customerService.getPagination();
    }
    this.customer.patient = this.setProfile(
      profileType.PATIENT,
      !!this.customer.isPatient
    );
    this.keyChangeCustomer = Math.random();
  }

  public newProfileCaregiver() {
    this.customer.caregiver = this.setProfile(
      profileType.CAREGIVER,
      !!this.customer.isCaregiver
    );
    this.keyChangeCustomer = Math.random();
  }

  public async loadCaregiverAssignment() {
    if (!!this.customer.patient && this.customer.patient.caregiver) {
      const findCaregiver = await customerService.findById(
        this.customer.patient.caregiver
      );
      this.caregiverAssignment = !!findCaregiver ? [findCaregiver] : [];
    }
  }

  public updateConditions(conditions: string[]) {
    this.customer.conditions = conditions.map(item => ({
      condition_id: +item
    }));
    this.newConditions = [...conditions];
  }

  public async getConditions() {
    const data: Array<{
      id: number;
      name: string;
    }> = await customerService.getConditions();
    this.conditions = data.map(item => ({
      value: String(item.id),
      name: item.name
    }));
  }

  public async getDoctors() {
    this.loadingDoctors = true;
    this.doctors = (await customerService.getDoctors()).map((item: Doctor) => {
      return {
        name: `${item.first_name} ${item.last_name} ${
          item.title ? ", " + item.title : ""
        }`,
        id: item.id
      };
    });
    this.loadingDoctors = false;
  }
  public getPatientsActions() {
    return [
      {
        icon: "fal fa-trash-alt",
        modalActions: {
          modalNumber: 1,
          modalQuestion: this.$t("caregiver_patient_confirmation").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            const patient = find(this.arrayToSave, ["id", arg.item.id]);
            if (!!patient) {
              this.arrayToSave = xorBy([patient], this.arrayToSave, "id");
              this.arrayTemporal = cloneDeep(this.arrayToSave);
            } else {
              this.arrayToDelete.push(arg.item as Customer);
            }
            arg.unselectModal();
          },
          modalCancelText: "no"
        }
      }
    ];
  }

  public async newModalPatient() {
    this.arrayTemporal = cloneDeep(this.arrayToSave);
    const arraysCompare = {
      patientsAssignment: this.patientsAssignment,
      arrayTemporal: this.arrayTemporal,
      arrayToDelete: this.arrayToDelete
    };
    this.$modals
      .load<Customer[]>(
        ModalPatientsComponent,
        {
          size: "fit"
        },
        { arraysCompare }
      )
      .then(data => {
        this.arrayToSave = data ? cloneDeep(data) : [];
        this.arrayToDelete = arraysCompare.arrayToDelete;
      })
      .catch(() => {
        // No action on close.
      });
  }

  public async addDoctor() {
    this.$emit(
      "saveCustomerBeforeAddingDoctor",
      this.customer.customer_id ? String(this.customer.customer_id) : "new"
    );
  }

  public isDoctorCreationAllowed() {
    return this.hasPermission(policyList.createDoctors);
  }

  public async validate(): Promise<boolean> {
    const validationPromises = [this.$validator.validateAll("caregiver")];
    const datepickers = [];

    if (this.customer.isCaregiver) {
      datepickers.push(this.$refs.medicalCardExpiration);
    }

    if (this.customer.isPatient) {
      datepickers.push(this.$refs.carefiverMedicalCardExpiration);
    }

    datepickers.forEach(ref => {
      validationPromises.push((ref as HelixDatePickerComponent).validate());
    });
    return (await Promise.all(validationPromises)).every(valid => valid);
  }

  public async customerLimit() {
    if (this.$route.name === "customers-add") {
      const limits = await limitsService
        .getCreateCustomerConsumptions()
        .then(response => response.data[0]);
      const addCustomerLimit = {
        limit_type: limits.strategy,
        is_limit_extended: 0,
        medicinal: limits.configs.map((item: any) => {
          const limit = {
            amount: item.config.amount,
            consumed: 0,
            name: item.name,
            measure: item.config.measure,
            rule_config_id: item.id,
            ext_limit_enabled: item.config.ext_limit_enabled
          };
          const limitDaily = {
            daily_amount: item.config.daily_amount,
            daily_measure: item.config.daily_measure
          };
          return limits.strategy === "one_daily_limit_with_monthly_overall"
            ? { ...limit, ...limitDaily }
            : limit;
        }),
        recreational: []
      };
      this.customerLimits = addCustomerLimit;
      this.extendedLimitsToggle = 0;
      this.locationLimitType = limits.strategy;
    } else {
      const {
        is_limit_extended,
        limit_type,
        ...limits
      } = await limitsService.getCustomerConsumptions(
        this.customer!.customer_id!
      );

      this.customerLimits = limits;
      this.extendedLimitsToggle = is_limit_extended;
      this.locationLimitType = limit_type;
    }
    this.setCustomerLimits(1);
  }
  public async mounted() {
    const limits = await limitsService
      .getCreateCustomerConsumptions()
      .then(response => response.data[0]);
    if (limits) {
      this.limitsExists = true;
    } else {
      this.limitsExists = false;
    }
    if (!this.conditions.length) {
      this.getConditions();
    }
    if (this.validated) {
      this.validate();
    }
    await this.customerLimit();

    this.getDoctors();

    if (this.customer.patient) {
      this.loadCaregiverAssignment();
    }
    if (this.customer.customer_limit) {
      this.customer.extendedLimits = true;
    }
    if (this.customer.caregiver) {
      this.loadOrders(this.customer.customer_id);
      this.rowActions = this.getPatientsActions();
    }
    this.fieldsToShow = itemsOrder;
    this.customer!.doctor_id = String(this.customer.doctor_id);

    if (this.locationLimitType === "multiple_overall_limits") {
      this.ruleUnits = [
        {
          value: "oz",
          text: this.$t("limit.ounces").toString()
        },
        {
          value: "g",
          text: this.$t("limit.grams").toString()
        },
        {
          value: "mg",
          text: this.$t("limit.milligrams").toString()
        },
        {
          value: "units",
          text: this.$t("limit.units").toString()
        }
      ];
    } else if (
      this.locationLimitType === "one_daily_limit_with_monthly_overall"
    ) {
      this.ruleUnits = [
        {
          value: "oz",
          text: this.$t("limit.ounces").toString()
        },
        {
          value: "g",
          text: this.$t("limit.grams").toString()
        },
        {
          value: "mg",
          text: this.$t("limit.milligrams").toString()
        }
      ];
    } else {
      this.ruleUnits = [
        {
          value: "oz",
          text: this.$t("limit.ounces").toString()
        },
        {
          value: "g",
          text: this.$t("limit.grams").toString()
        }
      ];
    }
  }

  public onChangeLimitCheckbox(limtiField: RuleConfig) {
    if (limtiField.customer_ext_limit_enabled) {
      limtiField.amount = null;
    } else {
      limtiField.amount = "";
    }

    EventBus.$emit("onChangeCustomerLimit");
    this.onChangeCustomerLimit();
  }
  public onChangeDailyLimitCheckbox(limtiField: RuleConfig) {
    if (limtiField.daily_customer_ext_limit_enabled) {
      limtiField.daily_amount = null;
    } else {
      limtiField.daily_amount = "";
    }

    EventBus.$emit("onChangeCustomerLimit");
    this.onChangeDailyCustomerLimit();
  }

  public setCustomerLimits(customerLimitCheckboxValue?: 0 | 1) {
    const arrayRules =
      (this.customerLimits && flatMap(this.customerLimits.medicinal)) || [];
    const filter = arrayRules.filter(
      (rule: RuleConfig) =>
        rule.ext_limit_enabled || rule.daily_ext_limit_enabled
    );
    if (filter.length) {
      this.extendedLimitShow = true;
    } else {
      this.extendedLimitShow = false;
    }

    const limit: RuleConfig[] = filter.map((rule: RuleConfig) => {
      const payloadData = {
        amount:
          rule.customer_ext_limit_enabled && customerLimitCheckboxValue
            ? rule.amount
            : null,
        id: rule.limits_rule_configs_id,
        measure: rule.measure,
        rule_config_id: rule.rule_config_id,
        name: rule.name,
        ext_limit_enabled: rule.ext_limit_enabled,
        consumed: 0,
        customer_ext_limit_enabled: customerLimitCheckboxValue
          ? rule.customer_ext_limit_enabled
          : 0
      };
      if (this.locationLimitType === "multiple_overall_limits") {
        if (rule.limits_rule_configs_id) {
          return payloadData;
        } else {
          delete payloadData.id;
          return payloadData;
        }
      } else if (
        this.locationLimitType === "one_daily_limit_with_monthly_overall"
      ) {
        const payloadDataDaily = {
          daily_amount:
            rule.daily_customer_ext_limit_enabled && customerLimitCheckboxValue
              ? rule.daily_amount
              : null,
          daily_measure: rule.daily_measure,
          daily_customer_ext_limit_enabled: customerLimitCheckboxValue
            ? rule.daily_customer_ext_limit_enabled
            : 0,
          daily_ext_limit_enabled: rule.daily_ext_limit_enabled
        };
        if (rule.limits_rule_configs_id) {
          return { ...payloadData, ...payloadDataDaily };
        } else {
          delete payloadData.id;
          return { ...payloadData, ...payloadDataDaily };
        }
      } else {
        if (rule.limits_rule_configs_id) {
          payloadData.amount = rule.amount;
          delete payloadData.customer_ext_limit_enabled;
          return payloadData;
        } else {
          payloadData.amount = rule.amount;
          delete payloadData.customer_ext_limit_enabled;
          delete payloadData.id;
          return payloadData;
        }
      }
    });
    if (
      this.locationLimitType === "multiple_overall_limits" &&
      customerLimitCheckboxValue
    ) {
      const checkFilterAmount = limit.filter(
        (rule: RuleConfig) =>
          Number(rule.amount) !== 0 && String(rule.amount) !== null
      );

      if (checkFilterAmount.length) {
        this.extendedLimitsToggle = 1;
      } else {
        this.extendedLimitsToggle = 0;
      }
    }
    if (
      this.locationLimitType === "one_daily_limit_with_monthly_overall" &&
      customerLimitCheckboxValue
    ) {
      if (limit.length) {
        if (
          (Number(limit[0].amount) !== 0 && String(limit[0].amount) !== null) ||
          (Number(limit[0].daily_amount) !== 0 &&
            String(limit[0].daily_amount) !== null)
        ) {
          this.extendedLimitsToggle = 1;
        } else {
          this.extendedLimitsToggle = 0;
        }
      }
    }

    if (!this.extendedLimitsToggle) {
      limit.map(values => {
        if (!this.extendedLimitsToggle) {
          values.amount = null;
          if (
            this.locationLimitType === "one_daily_limit_with_monthly_overall"
          ) {
            values.daily_amount = null;
          }
        }
      });
    }

    const array: CustomerLimitConfig = {
      customer_id: this.customer!.customer_id,
      is_limit_extended: this.extendedLimitsToggle,
      location_limit_type: this.locationLimitType,
      limit_config: limit
    };
    this.updateCustomerLimit = array;
    this.onChangeCustomerLimit();
  }
  public onChangeCustomerLimit(limtiField?: RuleConfig, field?: string) {
    if (limtiField && field && field === "measure") {
      if (limtiField.amount === "") {
        limtiField!.amount = null;
      } else {
        limtiField!.amount = "";
      }
      EventBus.$emit("onChangeCustomerLimit");
    }
    this.$emit("changeCustomerLimit", this.updateCustomerLimit);
  }
  public onChangeDailyCustomerLimit(limtiField?: RuleConfig, field?: string) {
    if (limtiField && field && field === "measure") {
      if (limtiField.daily_amount === "") {
        limtiField!.daily_amount = null;
      } else {
        limtiField!.daily_amount = "";
      }
      EventBus.$emit("onChangeDailyCustomerLimit");
    }
    this.$emit("changeDailyCustomerLimit", this.updateCustomerLimit);
  }
  public onExtendedLimitChange() {
    this.updateCustomerLimit!.is_limit_extended = this.extendedLimitsToggle;
    if (!this.extendedLimitsToggle) {
      this.setCustomerLimits(0);
    }
  }
  private setProfile(type: string, flag: boolean) {
    return flag
      ? find(this.customer.profiles, ["profile_type", type]) ||
          cloneDeep(defaultProfile)
      : null;
  }

  private async loadPatientsAssignment() {
    this.loadingPatients = true;
    this.patientsAssignment =
      (this.customer.customer_id &&
        this.customer.caregiver &&
        !this.patientsAssignment &&
        (await customerService.findPatients(this.customer.customer_id))) ||
      this.patientsAssignment;
    this.loadingPatients = false;
  }

  public get locationShow(): boolean {
    return this.currentLocation.state_id === 61; // Oklahoma state
  }
}
