export interface JournalNotes {
  id?: string | number | null;
  description?: { name: string | null; style: string };
  status?: { name: string; style: string };
  created_at?: string;
  due_date: string | null;
  title: string;
  note: string | null;
  priority: string;
  itemToUpdate?: string | number | null;
  itemToDelete?: number | null;
  item?: any;
}
export const JournalNotesDefault: JournalNotes = {
  title: "",
  note: "",
  priority: "",
  created_at: "",
  due_date: ""
};
