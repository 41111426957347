import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { JournalNotes, JournalNotesDefault } from "@/interfaces/journalNotes";
import { Location } from "@/interfaces/location";
import { JournalNotesTableMetadata } from "@/metadata/journalNotes";
import { customerService } from "@/services/customer.service";
import { journalActionService } from "@/services/journalNotes.actions.service";
import { messagesService } from "@/services/messages.service";

import {
  BooleanCheck,
  HelixDatePickerComponent,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import CustomerNotesDetailsComponent from "./CustomerNote/CustomerNotes.component";
import Template from "./JournalNotes.template.vue";
@Component({
  mixins: [Template],
  components: {
    TableComponent,
    CustomerNotesDetailsComponent,
    HelixDatePickerComponent
  },
  inject: ["$changes"]
})
export default class JournalNotesComponent extends Vue {
  // tslint:disable-next-line: array-type
  public get priorityItems(): Array<{ value: string; text: string }> {
    return [
      { value: "NORMAL", text: "Normal" },
      { value: "SHOW_NOTE_FLAG", text: "Show Note Flag" },
      { value: "POPUP_MODAL_NOTE_FLAG", text: "Pop-Up Modal & Note Flag" }
    ];
  }
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Prop({ default: false })
  public createPermission!: boolean;
  @Prop({ default: false })
  public editPermission!: boolean;

  public pagination: TablePagination = { ...TablePaginationDefault };
  public journals: JournalNotes[] = [];
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public rowActions: TableAction[] = journalActionService.getJournalNotesRowActions(
    this.editPermission,
    this.createPermission,
    this.editNote,
    this.journalDelete,
    this.deleteIndex
  );
  public loading: boolean = false;
  public note!: JournalNotes;
  public headers: TableHeader[] = JournalNotesTableMetadata;
  public defaultPriority: string = "NORMAL";
  public notes: string = "";
  public update: boolean = false;
  public updateNoteId!: string | number | null | undefined;
  public updatePosition!: number;
  public itemToUpdateExist: boolean = false;
  public itemToUpdateExistFlag: boolean = false;
  public deleteIndexId!: number;
  public sortTableColumn!: string;

  @Prop({ required: true })
  protected customer!: Customer;
  constructor() {
    super();
    this.note = { ...JournalNotesDefault, priority: "NORMAL" };
  }

  public priorityChanged(event: string) {
    this.note.priority = event;
  }

  public addNote() {
    if (!this.customer.journals) {
      this.customer.journals = [];
    }
    const note = {
      due_date: this.note.due_date,
      note: this.note.note,
      priority: this.note.priority,
      title: this.note.title
    };

    const updatedJournalNote = { ...note, created_at: "" };
    updatedJournalNote.priority = this.priorityCaseConversion(note);

    // to display valid journal created date format in table when note added
    updatedJournalNote.created_at = this.noteCreatedFormatChange();

    // to display valid due date format in table when note added
    updatedJournalNote.due_date = this.dueDateFormatChange(note.due_date);

    this.journals.unshift(updatedJournalNote);
    this.customer.journals!.unshift(note);
    this.pagination = {
      ...this.pagination
    } as TablePagination;

    // clear text in fields after adding note
    this.note = { ...JournalNotesDefault, priority: "NORMAL" };
    this.defaultPriority = "NORMAL";
  }

  public async created() {
    await this.loadData(this.pagination);
  }
  public async journalsData() {
    const response = await customerService.getJournals(
      this.$route.params.id,
      this.pagination,
      this.sortTableColumn
    );
    return response;
  }
  public journalDelete(arg: JournalNotes) {
    if (!this.customer.journals) {
      this.customer.journals = [];
    }
    const note = { ...this.note, itemToDelete: arg.item.id };
    this.journals.map((item, index) => {
      if (index === this.deleteIndexId) {
        this.journals.splice(index, 1);
        if (item.id) {
          this.customer.journals!.push(note);
        }
      }
    });
    this.customer.journals.map((item, index) => {
      if (!item.itemToDelete) {
        if (index === this.deleteIndexId) {
          this.customer.journals!.splice(index, 1);
        }
      } else {
        this.customer.journals!.push(note);
      }
      if (this.customer.journals!.length === 0) {
        this.customer.journals = ("" as unknown) as [];
      }
    });
  }

  public deleteIndex(arg: JournalNotes, id: number) {
    this.deleteIndexId = id;
  }

  public priorityCaseConversion(item: any) {
    if (item.priority === "Normal") {
      return "NORMAL";
    } else if (item.priority === "Show Note Flag") {
      return "SHOW_NOTE_FLAG";
    } else if (item.priority === "Pop-Up Modal & Note Flag") {
      return "POPUP_MODAL_NOTE_FLAG";
    } else if (item.priority === "NORMAL") {
      return "Normal";
    } else if (item.priority === "SHOW_NOTE_FLAG") {
      return "Show Note Flag";
    } else {
      return "Pop-Up Modal & Note Flag";
    }
  }
  public editNote(arg: JournalNotes, position: number) {
    this.note = { ...arg, itemToUpdate: arg.id };

    // default priority value when text field filled
    this.defaultPriority = this.priorityCaseConversion(arg);

    // for update button
    this.update = true;

    this.updateNoteId = arg.id;
    this.updatePosition = position;
  }

  public updateNote() {
    if (!this.customer.journals) {
      this.customer.journals = [];
    }

    const updatedNote = {
      ...this.note,
      itemToUpdate: this.updateNoteId,
      id: this.updateNoteId,
      priority: this.defaultPriority
    };

    updatedNote.priority = this.priorityCaseConversion(updatedNote);
    this.journals.map((item, index) => {
      if (!item.id) {
        if (index === this.updatePosition) {
          this.journals[index] = updatedNote;
        }
      }
    });
    this.journals.map((item, index) => {
      if (item.id) {
        if (item.id === this.updateNoteId) {
          this.journals[index] = updatedNote;
        }
      }
    });

    // to display valid due date format in table when note added
    updatedNote.due_date = this.dueDateFormatChange(this.note.due_date);

    const notePropertiesAccepted = {
      due_date: updatedNote.due_date,
      id: updatedNote.id,
      itemToUpdate: updatedNote.itemToUpdate,
      note: updatedNote.note,
      priority: updatedNote.priority,
      title: updatedNote.title
    };
    // sending accepted  priority format
    notePropertiesAccepted.priority = this.priorityCaseConversion(
      notePropertiesAccepted
    );

    // converting to valid due date
    if (notePropertiesAccepted.due_date) {
      if (notePropertiesAccepted.due_date!.includes("/")) {
        const [m, d, y] = notePropertiesAccepted.due_date!.split("/");
        notePropertiesAccepted.due_date = `${y}-${m}-${d}`;
      }
    }
    if (this.customer.journals.length >= 1) {
      this.customer.journals.map((item, index) => {
        if (item.id) {
          if (item.id === notePropertiesAccepted.itemToUpdate) {
            this.customer.journals![index] = notePropertiesAccepted;
            // not sending multiple updated item to array
            this.itemToUpdateExist = true;
          }
        } else {
          if (index === this.updatePosition) {
            this.itemToUpdateExistFlag = true;
            this.customer.journals![index] = notePropertiesAccepted;
          }
        }
      });
      if (!this.itemToUpdateExist && !this.itemToUpdateExistFlag) {
        this.customer.journals.push(notePropertiesAccepted);
      }
    } else {
      this.customer.journals.push(notePropertiesAccepted);
    }

    this.update = false;

    // clear text in fields after adding note
    this.note = { ...JournalNotesDefault, priority: "NORMAL" };
    this.defaultPriority = "NORMAL";
  }

  public async changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.loading = true;
    await this.getJournalsLog();
    this.loading = false;
  }

  public formattedResponse(response: any) {
    const updatedResponse = [...response.data.data].map(item => {
      item.priority = this.priorityCaseConversion(item);
      item.due_date = this.dueDateFormatChange(item.due_date);

      if (item.created_at) {
        const [y, m, d] = item.created_at.split(" ")[0].split("-");

        item.created_at =
          this.currentLocation!.date_format === "MM-DD-YYYY"
            ? `${m}/${d}/${y}`
            : `${d}/${m}/${y}`;
      }
      return item;
    });
    return updatedResponse;
  }

  public async getJournalsLog() {
    try {
      if (this.$route.params.id) {
        const response = await this.journalsData();
        this.pagination = {
          currentPage: response.data.current_page,
          itemsPerPage: response.data.per_page,
          itemsPerPageOptions: [5, 10, 20, 50],
          totalItems: response.data.total,
          from: response.data.from,
          to: response.data.to
        } as TablePagination;

        this.journals = this.formattedResponse(response);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  public async filter(header: TableHeader) {
    this.sortTableColumn = header.descending
      ? `-${header.value},priority`
      : `${header.value},priority`;
    const response = await customerService.getJournals(
      this.$route.params.id,
      this.pagination,
      this.sortTableColumn
    );
    this.journals = this.formattedResponse(response);
  }
  public dueDateFormatChange(dueDate: string | null) {
    if (dueDate) {
      if (dueDate.includes("-")) {
        const [y, m, d] = dueDate.split("-");
        return this.currentLocation!.date_format === "MM-DD-YYYY"
          ? `${m}/${d}/${y}`
          : `${d}/${m}/${y}`;
      } else if (dueDate.includes("/")) {
        const [m, d, y] = dueDate!.split("/");
        return this.currentLocation!.date_format === "MM-DD-YYYY"
          ? `${m}/${d}/${y}`
          : `${d}/${m}/${y}`;
      }
    }
    return null;
  }

  public noteCreatedFormatChange() {
    const date = new Date();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return this.currentLocation!.date_format === "MM-DD-YYYY"
      ? `${month}/${day}/${year}`
      : `${day}/${month}/${year}`;
  }

  private async loadData(pagination: TablePagination) {
    try {
      if (this.$route.params.id) {
        const response = await this.journalsData();
        this.journals = this.formattedResponse(response);
        this.pagination = await customerService.getPagination(true);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
}
