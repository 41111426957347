import ItemManagerComponent from "@/components/metrc/itemManager/itemManager.component";
import CustomerHistoryComponent from "@/components/retail/customers/customersForm/customerHistory/CustomerHistory.component";
import JournalNotesComponent from "@/components/retail/customers/customersForm/customerJournal/JournalNotes.component";
import DocumentsUploadComponent from "@/components/retail/customers/customersForm/documentsUpload/DocumentsUpload.component";
import LoyaltyComponent from "@/components/retail/customers/customersForm/loyalty/Loyalty.component";
import MedicalCaregiverComponent from "@/components/retail/customers/customersForm/medicalCaregiver/MedicalCaregiver.component";
import MoreInfoComponent from "@/components/retail/customers/customersForm/moreInfo/MoreInfo.component";
import PurchasingDetailsComponent from "@/components/retail/customers/customersForm/purchasingDetails/PurchasingDetails.component";
import {
  clearModel,
  profileType
} from "@/components/retail/customers/customersForm/utils";
import { LimitFieldComponent } from "@/components/sharedComponents/limitField/limitField.component";
import { SelectImage } from "@/components/sharedComponents/selectImage/SelectImage.enum";
import { policyList } from "@/enums/permissions";
import { EventBus } from "@/event-bus";
import {
  Attachment,
  basicInfoModel,
  Customer,
  CustomerBasicInfo,
  CustomerLimitConfig,
  CustomerModel,
  defaultCustomer,
  defaultCustomerModel,
  FileAttachment
} from "@/interfaces/customer";
import { customerLimits } from "@/interfaces/limits";
import { Location } from "@/interfaces/location";
import { PusherNotification } from "@/interfaces/notification";
import { RetailSettings } from "@/interfaces/retailSettings";
import { pusherEvents, store } from "@/internal";
import { customerService } from "@/services/customer.service";
import { limitsService } from "@/services/limits.service";
import { messagesService } from "@/services/messages.service";
import { BooleanCheck, Callback, PageNavAction } from "@/types/types";
import differenceInDays from "date-fns/differenceInDays";
import {
  DynamicFormComponent,
  FormField,
  FormFieldTypes,
  FormModel,
  TableLimitComponent
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import pick from "lodash/pick";
import union from "lodash/union";
import xor from "lodash/xor";
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import Template from "./CustomersForm.template.vue";

const namespace: string = "CustomerModule";

@Component({
  components: {
    PurchasingDetailsComponent,
    MedicalCaregiverComponent,
    MoreInfoComponent,
    JournalNotesComponent,
    CustomerHistoryComponent,
    DocumentsUploadComponent,
    LoyaltyComponent,
    DynamicFormComponent,
    TableLimitComponent
  },
  mixins: [Template],
  inject: ["$changes"]
})
export default class CustomersFormComponent extends Vue {
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  public isPharmacyEnabled = false;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public viewPermission = false;
  public editPermission = false;
  public disableEditMode = false;
  public createModifyCustomers = false;
  public createPermission: boolean = false;
  @Provide()
  public validator = this.$validator;
  @Prop({
    default: () => {
      return { ...defaultCustomer };
    }
  })
  public currentModel!: Customer;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Mutation("setSavedForCheckin", { namespace: "CustomerModule" })
  public setSavedForCheckin!: Callback;
  /**
   * fields to render
   */
  @Getter("form", { namespace })
  public form!: FormField[];
  @Mutation("setForm", { namespace: "CustomerModule" })
  public setForm!: Callback;
  /**
   * @method to save
   */
  @Getter("currentCustomer", { namespace })
  public current!: Customer | null;
  /**
   * @method to save
   */
  @Action("saveCustomer", { namespace })
  public saveCustomer!: Callback;
  /**
   * @method to find a customer
   */
  @Action("findCustomerProfile", { namespace })
  public findCustomerProfile!: Callback;
  @Action("setCustomer", { namespace })
  public setCustomer!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public isMedical = false;
  public ordersLoadedforCaregiver = false;
  public model: CustomerModel = { ...defaultCustomerModel };
  public isLoading = false;
  public patients: Customer[] = [];
  public tabActive = "";
  public customerLimits: customerLimits | null = null;
  public updatedCustomerLimit: CustomerLimitConfig | null = {
    customer_id: "",
    location_limit_type: "multiple_overall_limit",
    is_limit_extended: 0,
    limit_config: []
  };
  public loadDataTab: string[] = [];
  public locationLimitType!: string;
  public invalidDocument = false;
  public invalidFileSize = false;
  public invalidDocExtension = false;
  public edit = false;
  public basicInfoModel: CustomerBasicInfo = basicInfoModel;
  public tabsErrors = {
    basicInfo: false,
    medicalCaregiver: false,
    moreInfo: false,
    journalNotes: false
  };
  public documentsToSave: FileAttachment[] = [];
  public fileExtension = "";
  public fileSize = 0;
  public invalidFileName: boolean = false;

  public isLoadedDataTab(tab: string) {
    return this.loadDataTab.includes(tab);
  }

  public pushDataTab(tab: string) {
    if (!this.loadDataTab.includes(tab)) {
      this.loadDataTab.push(tab);
    }
  }

  public async caregiverValidation(): Promise<boolean> {
    if (this.$refs.caregiver) {
      // @ts-ignore
      return await this.$refs.caregiver.validate(this.isMedical);
    }

    return (
      !this.isMedical || !!this.model.isPatient || !!this.model.isCaregiver
    );
  }

  public async moreInfoValidation(): Promise<boolean> {
    let moreInfoAllFields = false;
    if (this.$refs.moreInfo) {
      // @ts-ignore
      moreInfoAllFields = await this.$refs.moreInfo.validate();
    }
    return this.isPharmacyEnabled ? moreInfoAllFields : true;
  }

  public async saveCustomerBeforeAddingDoctor(customerId: string) {
    const form = await (this.$refs
      .customerForm as DynamicFormComponent).submit();
    const isValidCaregiver = await this.caregiverValidation();
    const isValidMoreInfo = await this.moreInfoValidation();
    this.tabsErrors.basicInfo = !form.valid;
    this.tabsErrors.medicalCaregiver = !isValidCaregiver;
    this.tabsErrors.moreInfo = !isValidMoreInfo;
    if (!form.valid) {
      this.tabActive = "basic-info";
    } else if (!isValidCaregiver) {
      this.pushDataTab("medical-caregiver");
      this.tabActive = "medical-caregiver";
    } else if (!isValidMoreInfo) {
      this.pushDataTab("more-info");
      this.tabActive = "more-info";
    } else {
      store.commit("CustomerModule/setNavigateToAddDoctor", true);
      await this.save();
    }
  }
  public formChange(form: any) {
    if (!form.model.avatar) {
      this.invalidDocument = false;
      return;
    }
    // getting file extension
    this.fileExtension = form.model.avatar.name.match(/\.([^\.]+)$/)![1];
    // getting file size in "mega bytes" by dividing with 1000000
    this.fileSize = form.model.avatar.size / 1000000;
    // only " alphanumeric,_ , - , ." characters are allowed in file name
    if (/([^a-zA-Z0-9_.\-])/.test(form.model.avatar.name)) {
      this.invalidFileName = true;
    } else {
      this.invalidFileName = false;
    }
    if (
      !store.getters["AuthModule/fileImageExtention"].includes(
        this.fileExtension
      ) ||
      this.fileSize > store.getters["AuthModule/fileUploadLimit"] ||
      this.invalidFileName
    ) {
      this.invalidDocument = true;
    } else {
      this.invalidDocument = false;
    }
  }
  public async save() {
    if (this.invalidDocument) {
      const invalidDocMsg: string | string[] = [];
      if (
        !store.getters["AuthModule/fileImageExtention"].includes(
          this.fileExtension
        )
      ) {
        invalidDocMsg.push(
          String(
            this.$t("security.invalid_file_extention", {
              fileExtention: store.getters["AuthModule/fileImageExtention"]
            })
          )
        );
      }
      if (this.fileSize > store.getters["AuthModule/fileUploadLimit"]) {
        invalidDocMsg.push(
          String(
            this.$t("security.invalid_file_size", {
              fileSize: store.getters["AuthModule/fileUploadLimit"]
            })
          )
        );
      }
      if (this.invalidFileName) {
        invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
      }
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        invalidDocMsg,
        "error"
      );
      return;
    }
    if (this.locationLimitType === "multiple_overall_limits") {
      const checkFilterAmount = this.updatedCustomerLimit!.limit_config!.filter(
        rule => Number(rule.amount) !== 0 && String(rule.amount) !== null
      );
      if (checkFilterAmount.length) {
        this.updatedCustomerLimit!.is_limit_extended = 1;
      } else {
        this.updatedCustomerLimit!.is_limit_extended = 0;
      }
    }
    const limitPayload: CustomerLimitConfig | null = cloneDeep(
      this.updatedCustomerLimit
    );
    let limitsError = false;
    if (this.updatedCustomerLimit!.is_limit_extended) {
      if (
        limitPayload &&
        limitPayload.limit_config &&
        limitPayload.limit_config.length
      ) {
        limitPayload.limit_config.map(limits => {
          if (
            this.locationLimitType === "one_daily_limit_with_monthly_overall"
          ) {
            if (
              (limits.customer_ext_limit_enabled && !limits.amount) ||
              (limits.daily_customer_ext_limit_enabled && !limits.daily_amount)
            ) {
              limitsError = true;
              EventBus.$emit("onCheckingCustomerLimit");
            }
          } else {
            limits.ext_limit_enabled =
              this.locationLimitType === "multiple_overall_limits"
                ? limits.customer_ext_limit_enabled
                : limits.ext_limit_enabled;
            if (
              limits.ext_limit_enabled &&
              (!limits.amount || limits.amount === 0)
            ) {
              limitsError = true;
              EventBus.$emit("onCheckingCustomerLimit");
            }
          }
        });
      } else {
        messagesService.renderWarningMessage(
          "customer_form_extended_limits_error_message"
        );
        return;
      }
    }
    if (limitsError) {
      return;
    }
    const form = await (this.$refs
      .customerForm as DynamicFormComponent).submit();
    const isValidCaregiver = await this.caregiverValidation();
    const isValidMoreInfo = await this.moreInfoValidation();
    this.tabsErrors.basicInfo = !form.valid;
    this.tabsErrors.medicalCaregiver = !isValidCaregiver;
    this.tabsErrors.moreInfo = !isValidMoreInfo;

    if (!form.valid) {
      this.tabActive = "basic-info";
    } else if (!isValidCaregiver) {
      this.pushDataTab("medical-caregiver");
      this.tabActive = "medical-caregiver";
    } else if (isValidCaregiver && !isValidMoreInfo) {
      this.pushDataTab("more-info");
      this.tabActive = "more-info";
    } else {
      const basicInfoForm = pick(
        form.currentModel,
        Object.keys(basicInfoModel)
      );
      let patientsAssignment: string[] = [];
      const isCheckIn =
        store.getters["CustomerModule/customerRouteforSave"] === "check-in";
      const isCallIn =
        store.getters["CustomerModule/customerRouteforSave"] === "call-in";
      if (isCheckIn && this.model.caregiver && this.isMedical) {
        patientsAssignment = (await customerService.findPatients(
          this.model.customer_id!
        )).map(patient => patient.customer_id!);
      }
      if (
        this.isMedical &&
        isCheckIn &&
        !this.model.patient &&
        !xor(
          union(patientsAssignment, this.model.patients!.attach),
          this.model.patients!.detach
        ).length
      ) {
        const editOn = await customerService.infoModalValidate(
          `${this.$tc("customer.msg_validation_checkin")}`
        );
        if (editOn) {
          this.tabActive = "medical-caregiver";
        }
      } else if (
        isCallIn &&
        !this.model.patient &&
        !xor(
          union(this.model.patients!.assigned, this.model.patients!.attach),
          this.model.patients!.detach
        ).length
      ) {
        await customerService.notPatientSelectedModal(this.model);
        this.tabActive = "medical-caregiver";
      } else {
        this.isLoading = true;
        this.processProfilePatient();
        this.processProfileCaregiver();
        this.processAttachment();
        this.model.doctor_id = Number(this.model.doctor_id) || null;
        if (form.currentModel!.avatar === null) {
          basicInfoForm.avatar = SelectImage.DELETE_CODE;
        } else if (form.currentModel!.avatar) {
          basicInfoForm.avatar = form.currentModel!.avatar;
        } else {
          basicInfoForm.avatar = undefined;
        }

        this.model = {
          ...this.model,
          ...basicInfoForm
        };

        const dataModel = clearModel(cloneDeep(this.model));
        await this.saveCustomer({
          customer: dataModel,
          customerSalesLimits: limitPayload
        });
        this.isLoading = false;
      }
    }
  }

  public onChangeCustomerLimit(customerLimit: CustomerLimitConfig) {
    this.updatedCustomerLimit = customerLimit;
  }
  public cancel() {
    if (store.getters["CustomerModule/customerRouteforSave"] === "customers") {
      this.$router.push({
        name: "customers-list"
      });
    } else if (
      store.getters["CustomerModule/customerRouteforSave"] === "check-in"
    ) {
      this.$router.push({
        name: "check-in"
      });
    } else if (
      store.getters["CustomerModule/customerRouteforSave"] === "call-in"
    ) {
      this.$router.push({
        name: "pos-customers-list"
      });
    } else {
      this.$router.push({
        name: "customers-management"
      });
    }
  }

  protected async loadCustomer() {
    this.isLoading = true;
    let customer: CustomerModel = { ...defaultCustomerModel };
    if (this.$route.params.id) {
      await this.findCustomerProfile({
        id: this.$route.params.id,
        redirect: true
      });
      customer = {
        ...defaultCustomerModel,
        ...(!this.current ? this.currentModel : this.current)
      };

      this.customerLimits = null;
      const {
        limit_type,
        ...limits
      } = await limitsService.getCustomerConsumptions(
        this.current!.customer_id!
      );
      this.customerLimits = limits;
      this.locationLimitType = limit_type;
      if (this.locationLimitType === "one_daily_limit_with_monthly_overall") {
        this.customerLimits!.medicinal = customerService.setOneDailyMonthlyLimitPOS(
          this.customerLimits!.medicinal[0]
        );
      }
    } else if (this.$route.params.newCustomer) {
      const newCustomer = JSON.parse(this.$route.params.newCustomer);
      customer = {
        ...defaultCustomerModel,
        ...this.currentModel,
        ...newCustomer
      };
      this.setSavedForCheckin(true);
    } else {
      customer = { ...defaultCustomerModel, ...this.currentModel };
      const limits = await limitsService
        .getCreateCustomerConsumptions()
        .then(response => response!.data[0]);
      if (limits) {
        this.locationLimitType = "";
        const addCustomerLimit = {
          limit_type: this.locationLimitType,
          medicinal:
            this.locationLimitType !== "one_daily_limit_with_monthly_overall"
              ? limits.configs &&
                limits.configs.map(
                  (item: {
                    config: { amount: number | string | null; measure: string };
                    name: string;
                    id: number | string;
                  }) => {
                    return {
                      amount: item.config.amount,
                      consumed: 0,
                      name: item.name,
                      measure: item.config.measure,
                      rule_config_id: item.id
                    };
                  }
                )
              : customerService.setOneDailyMonthlyLimitPOS(
                  limits.configs[0].config
                ),
          recreational: []
        };
        this.customerLimits = addCustomerLimit;
      }
    }
    customer.caregiver =
      find(customer.profiles, ["profile_type", profileType.CAREGIVER]) || null;
    customer.patient =
      find(customer.profiles, ["profile_type", profileType.PATIENT]) || null;
    customer.isCaregiver = !!customer.caregiver;
    customer.isPatient = !!customer.patient;
    this.basicInfoModel = {
      ...pick(customer, Object.keys(basicInfoModel)),
      avatar: undefined,
      avatar_medium_url: customer.avatar
        ? customer.avatar.avatar_medium_url
        : null
    } as CustomerBasicInfo;

    if (customer.customer_id!) {
      this.documentsToSave = (await customerService.getAllAttachments(
        customer.customer_id!
      )).map((item: FileAttachment) => {
        return {
          id: item.id!,
          name: item.media_file_name!,
          url: item.media_thumb_url!,
          lastModifiedDate: item.media_updated_at!,
          location: item.location
        } as Attachment;
      });
    }

    this.model = cloneDeep(customer);

    setTimeout(async () => {
      if (this.$route.name === "customers-edit") {
        this.pushDataTab("medical-caregiver");
        this.pushDataTab("more-info");
      }

      if (this.$route.query.newDocId) {
        this.pushDataTab("medical-caregiver");
        this.tabActive = "medical-caregiver";
      }
      if (Boolean(this.$route.query.customerDetailsIncomplete)) {
        const form = await (this.$refs
          .customerForm as DynamicFormComponent).submit();
        const isValidCaregiver = await this.caregiverValidation();
        const isValidMoreInfo = await this.moreInfoValidation();
        this.tabsErrors.basicInfo = !form.valid;
        this.tabsErrors.medicalCaregiver = !isValidCaregiver;
        this.tabsErrors.moreInfo = !isValidMoreInfo;
        if (!form.valid) {
          this.tabActive = "basic-info";
        } else if (!isValidCaregiver) {
          this.pushDataTab("medical-caregiver");
          this.tabActive = "medical-caregiver";
        } else if (!isValidMoreInfo) {
          this.pushDataTab("more-info");
          this.tabActive = "more-info";
        }
      }
      this.isLoading = false;
    }, 10);
  }

  protected setFormConfig() {
    this.setForm([
      {
        component: FormFieldTypes.textField,
        label: this.$t("customer.first_name"),
        name: "first_name",
        options: {
          validationRules: ["required"],
          row: 1,
          flex: 3,
          disableCondition: () => {
            return this.disableEditMode;
          }
        }
      },
      {
        component: FormFieldTypes.textField,
        label: this.$t("customer.middle_name"),
        name: "middle_name",
        options: {
          row: 1,
          flex: 2,
          disableCondition: () => {
            return this.disableEditMode;
          }
        }
      },
      {
        component: FormFieldTypes.textField,
        label: this.$t("customer.last_name"),
        name: "last_name",
        options: {
          validationRules: ["required"],
          row: 1,
          flex: 3,
          disableCondition: () => {
            return this.disableEditMode;
          }
        }
      },
      {
        component: FormFieldTypes.textField,
        label: this.$t("email"),
        name: "email",
        options: {
          validationRules: ["email"],
          row: 2,
          flex: 3,
          disableCondition: () => {
            return this.disableEditMode;
          }
        }
      },
      {
        component: FormFieldTypes.datepicker,
        label: this.$t("birthday"),
        name: "birthday",
        options: {
          row: 2,
          flex: 2,
          pickerOptions: {
            name: "birthday",
            required: true,
            "value-format": "yyyy-MM-dd",
            disabled: this.disableEditMode,
            "picker-options": {
              disabledDate: (date: Date) =>
                differenceInDays(new Date(), date) < 0
            }
          }
        }
      },
      {
        component: FormFieldTypes.textField,
        label: this.$t("phone"),
        name: "phone",
        options: {
          row: 2,
          flex: 3,
          disableCondition: () => {
            return this.disableEditMode;
          }
        }
      },
      {
        component: FormFieldTypes.textField,
        label: this.$t("customer.id_number"),
        name: "id_number",
        options: {
          row: 3,
          flex: 3,
          disableCondition: () => {
            return this.disableEditMode;
          }
        }
      },
      {
        component: FormFieldTypes.autocomplete,
        label: this.$t("customer.id_type"),
        name: "id_type",
        options: {
          selectOptions: () => [
            { value: "ID", text: this.$t("id").toString() },
            { value: "PASSPORT", text: this.$t("passport").toString() },
            { value: "LICENSE", text: this.$t("license_number").toString() }
          ],
          associationText: "text",
          associationValue: "value",
          row: 3,
          flex: 2,
          disableCondition: () => {
            return this.disableEditMode;
          }
        }
      },
      {
        component: FormFieldTypes.datepicker,
        label: this.$t("expiration"),
        name: "id_expiration",
        options: {
          row: 3,
          flex: 3,
          pickerOptions: {
            name: "id_expiration",
            "value-format": "yyyy-MM-dd",
            disabled: this.disableEditMode,
            "picker-options": {
              disabledDate: (date: Date) =>
                differenceInDays(new Date(), date) > 0
            }
          }
        }
      },
      {
        component: FormFieldTypes.image,
        label: this.$t("customer.avatar"),
        name: "avatar",
        options: {
          row: 1,
          flex: 4,
          defaultImageField: "avatar_medium_url",
          disableCondition: () => {
            return this.disableEditMode;
          },
          contextRules: (item: FormModel) => {
            // getting file extension
            const fileExtension = item.avatar.name.match(/\.([^\.]+)$/)![1];
            // getting file size in "mega bytes" by dividing with 1000000
            const fileSize = item.avatar.size / 1000000;
            const invalidDocMsg: string | string[] = [];
            if (
              !store.getters["AuthModule/fileImageExtention"].includes(
                fileExtension
              )
            ) {
              invalidDocMsg.push(
                String(
                  this.$t("security.invalid_file_extention", {
                    fileExtention:
                      store.getters["AuthModule/fileImageExtention"]
                  })
                )
              );
            }
            if (fileSize > store.getters["AuthModule/fileUploadLimit"]) {
              invalidDocMsg.push(
                String(
                  this.$t("security.invalid_file_size", {
                    fileSize: store.getters["AuthModule/fileUploadLimit"]
                  })
                )
              );
            }
            if (/([^a-zA-Z0-9_.\-])/.test(item.model.avatar.name)) {
              invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
            }

            if (invalidDocMsg.length) {
              messagesService.showMessage(
                "fal fa-exclamation-triangle",
                invalidDocMsg,
                "error"
              );
            }
          }
        }
      }
    ]);
  }
  protected async mounted() {
    this.viewPermission = this.hasPermission(policyList.viewCustomersList);
    this.editPermission = this.hasPermission(
      policyList.modifyCustomerProfileDetails
    );
    this.createPermission = this.hasPermission(policyList.createCustomers);
    this.createModifyCustomers = this.hasPermission(
      policyList.createModifyCustomers
    );
    if (
      (this.$route.name === "customers-view" &&
        this.viewPermission &&
        !this.editPermission) ||
      (this.$route.name === "customers-view" && !this.createModifyCustomers)
    ) {
      this.disableEditMode = true;
    }

    await this.setFormConfig();

    this.setPageNav({
      title: "customer.customer_profile",
      isLoading: () => this.isLoading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            action: this.save,
            vuetifyProps: () => ({
              loading: this.isLoading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isLoading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.isMedical = this.currentLocation.location_type === "RETAIL_MEDICAL";
    await this.loadCustomer();
    this.$changes.watch(
      [pusherEvents.customer],
      this.loadCustomer,
      (data: PusherNotification) => {
        if (this.model && this.model.customer_id) {
          return !!data.message.find(
            n => String(n.item_id) === this.model.customer_id
          );
        }
        return false;
      }
    );
    this.isPharmacyEnabled = !!this.currentRetailSettings.pharmacy_mode_enabled;
  }

  protected beforeDestroy() {
    this.setForm([]);
    this.setSavedForCheckin(false);
    this.setCustomer(null);
  }

  private processProfilePatient() {
    this.model.profiles = this.model.profiles || [];
    const index = this.model.profiles!.findIndex(
      p => p.profile_type === profileType.PATIENT
    );
    if (this.model.patient) {
      const dataProfile = { ...this.model.patient };
      dataProfile.profile_type = profileType.PATIENT;

      if (!this.model.conditions || this.model.conditions.length === 0) {
        this.model.conditions = null;
      }

      if (index >= 0) {
        // edit Patient
        this.model.profiles[index] = dataProfile;
      } else {
        // new Patient
        this.model.profiles.push(dataProfile);
      }
    } else if (index >= 0) {
      // delete Caregiver
      this.model.profiles!.splice(index, 1);
      this.model.conditions = null;
    }
  }

  private processProfileCaregiver() {
    this.model.profiles = this.model.profiles || [];
    const index = this.model.profiles.findIndex(
      p => p.profile_type === profileType.CAREGIVER
    );
    if (this.model.caregiver) {
      this.model.caregiver.profile_type = profileType.CAREGIVER;
      this.model.patients = {
        attach: this.model.patients.attach,
        detach: this.model.patients.detach
      };

      if (index >= 0) {
        // New Caregiver
        this.model.profiles[index] = this.model.caregiver;
      } else {
        // edit Caregiver
        this.model.profiles.push(this.model.caregiver);
      }
    } else if (index >= 0) {
      // delete Caregiver
      this.model.profiles!.splice(index, 1);
      this.model.patients = {
        attach: [],
        detach: this.model.patients.assigned || []
      };
    }
  }

  private processAttachment() {
    if (this.model.documents) {
      this.model.attachments = !!this.model.documents.length
        ? this.model.documents!.map(item =>
            item._destroy ? item : ({ attachment: item } as Attachment)
          )
        : [];
      delete this.model.documents;
      if (!this.model.attachments!.length) {
        delete this.model.attachments;
      }
    } else {
      delete this.model.attachments;
    }
  }
}
