import { Country, Currency, State, Timezone } from "@/interfaces/geographical";
import HttpService from "./http.service";

/**
 * customer service
 * @override
 */
class GeographicalService extends HttpService {
  protected countries: Country[] = [];
  public async getCountries(): Promise<Country[]> {
    this.uri = `${process.env.VUE_APP_CORE_URL}/countries`;
    this.query = { no_pagination: true, sort: "name" };
    const response = await super.get(this.query);
    const USA = {
      id: "US",
      fips_code: "US",
      name: "United States"
    };
    const countries = response.data.filter(
      (country: { id: string }) => country.id !== "USA"
    );
    countries.unshift(USA);
    this.countries = countries;
    return this.countries;
  }

  public async getStates(country: string | undefined): Promise<State[]> {
    const countries = this.countries.length
      ? this.countries
      : await this.getCountries();
    const selectedCountry = countries.find(item => item.name === country);
    const countryId = (selectedCountry && selectedCountry.id) || null;
    this.query = { no_pagination: true, sort: "name" };
    if (countryId) {
      this.query.country_id = countryId;
    }
    this.uri = `${process.env.VUE_APP_CORE_URL}/states`;
    const response = await super.get(this.query);
    return response.data;
  }

  public async getCities(stateId: number | undefined): Promise<object[]> {
    this.uri = `${process.env.VUE_APP_CORE_URL}/cities`;
    this.query = {};
    if (stateId) {
      // @ts-ignore
      this.query.state_id = stateId;
    }
    const response = await super.get(this.query);
    return response.data.data;
  }

  public async getTimezones(): Promise<Timezone[]> {
    this.uri = `${process.env.VUE_APP_CORE_URL}/timezones`;
    this.query = { sort: "zone" };
    const response = await super.get(this.query);
    return response.data.data.map((timezone: Timezone) => {
      return {
        id: timezone.id,
        name: timezone.zone
      };
    });
  }

  public async getCurrencies(): Promise<Currency[]> {
    this.uri = `${process.env.VUE_APP_CORE_URL}/currencies`;
    this.query = {};
    const response = await super.get(this.query);
    return response.data.data.map((currency: Currency) => {
      return {
        id: currency.id,
        code: currency.code
      };
    });
  }
}

export const geographicalService = new GeographicalService();
