import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./ItemAggregator.template.vue";

interface AggregatorInputOption {
  name: string;
  value: string;
}

@Component({
  mixins: [Template]
})
export default class ItemAggregatorComponent extends Vue {
  @Prop() public options!: AggregatorInputOption[];
  @Prop() public selected!: string[];
  @Prop({ default: "auto" }) public menuMaxWith!: number;
  @Prop({ default: "type_to_search" }) public placeholder!: string;
  @Prop({ default: `id_${Math.random()}` }) public id!: string;
  @Prop() public disabled!: boolean;
  public loader = false;

  protected initialSet: AggregatorInputOption[] = [];
  protected selectionSearch: string = "";

  protected model: string[] = [];

  @Watch("options")
  public onOptionsChange(newValue: AggregatorInputOption[]) {
    if (this.initialSet.length === 0) {
      this.initialSet = newValue;
    }
  }

  public get selectedOptions() {
    return this.options.filter(
      item => this.selected.indexOf(String(item.value)) > -1
    );
  }

  public get availableOptions() {
    if (!this.options) {
      return [];
    } else {
      return this.options.filter(
        item => this.selected.indexOf(item.value) <= -1
      );
    }
  }

  public add(option: string) {
    const isPresent = this.selected.find(
      (value: string) => value === String(option)
    );
    if (!isPresent) {
      this.selected.push(String(option));
      this.raise();
    }
  }

  public remove(option: string) {
    setTimeout(() => {
      this.loader = true;
    }, 10);
    const i = this.selected.indexOf(String(option));
    if (i > -1 && option) {
      this.selected.splice(i, 1);
      this.raise();
    }
    setTimeout(() => {
      this.loader = false;
    }, 10);
  }

  public raise() {
    this.$emit("outgoingData", this.selected);
  }
}
