import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";
export const JournalNotesTableMetadata: TableHeader[] = [
  {
    label: i18n.t("journal_entry_title").toString(),
    value: "title",
    fieldComponent: TableFieldType.string,
    conditionalClassFn: () => "tdt__note__title ",
    // @ts-ignore
    size: 15,
    sortable: true,
    descending: false
  },
  {
    label: i18n.t("timeclock_card.change_request.note").toString(),
    value: "note",
    fieldComponent: TableFieldType.string,
    conditionalClassFn: () => "tdt__note__table__data",
    // @ts-ignore
    size: 38,
    sortable: true,
    descending: false
  },
  {
    label: i18n.t("priority").toString(),
    value: "priority",
    fieldComponent: TableFieldType.string,
    // @ts-ignore
    size: 10,
    sortable: true,
    descending: false
  },
  {
    label: i18n.t("date_created").toString(),
    value: "created_at",
    fieldComponent: TableFieldType.fnsDate,
    // @ts-ignore
    size: 6,
    sortable: true
  },
  {
    label: i18n.t("due_date").toString(),
    value: "due_date",
    fieldComponent: TableFieldType.string,
    // @ts-ignore
    size: 6.3,
    sortable: true
  }
];
