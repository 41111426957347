import { JournalNotes } from "@/interfaces/journalNotes";
import { i18n } from "@/plugins/i18n";
import { TableAction } from "helix-vue-components";
import Vue from "vue";

class JournalActionService extends Vue {
  public getJournalNotesRowActions(
    editFlag: boolean,
    createFlag: boolean,
    editNote: (item: JournalNotes, position: number) => void,
    journalDelete: (item: JournalNotes, position: number) => void,
    deleteIndex: (item: JournalNotes, position: number) => void
  ): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        id: "btn_edit_customer",
        action: (arg: JournalNotes, position: number) => {
          editNote(arg, position);
        },
        visibleCondition: () => {
          return !editFlag;
        }
      },
      {
        icon: "fal fa-trash-alt",
        id: "btn_delete_customer",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("journal_notes_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg, position) => {
            journalDelete(arg, position);
            arg.unselectModal();
          },
          modalIdSuccess: "btn_delete_yes",
          modalCancelText: "no",
          modalIdCancel: "btn_delete_no"
        },
        visibleCondition: () => {
          return !editFlag;
        },
        action: (arg, id) => {
          deleteIndex(arg, id);
        }
      }
    ];
  }
}
export const journalActionService = new JournalActionService();
