import { SelectImage } from "@/components/sharedComponents/selectImage/SelectImage.enum";
import { JournalNotes } from "./journalNotes";
import { customerLimits, RuleConfig } from "./limits";
import { MemberLevel } from "./memberLevel";
import { Order } from "./order";

export type CustomerType = "REGISTERED" | "ANONYMOUS";

export interface Customer {
  account_id?: number;
  state?: number | string | null;
  profiles?: Profile[];
  avatar?: CustomerAvatar | null;
  limits?: customerLimits[];
  applicable_limits?: customerLimits[];
  customer_limit?: CustomerLimitConfig[];
  customer_journals_count?: number;
  order_saved?: boolean;
  online_pending?: boolean;
  online_filled?: boolean;
  orders?: Order[];
  tax_exempt?: boolean | null;
  pending?: boolean;
  member_level?: MemberLevel;
  avatar_variants?: string[] | null;
  birthday: string | null;
  call_in_filled?: boolean;
  call_in_pending?: boolean;
  cellphone_carrier: string | null;
  cellphone: string | null;
  created_at?: string;
  first_name?: string;
  last_name?: string;
  middle_name: string;
  shows_checkin_note: boolean;
  referred_by?: string;
  id_is_temporary?: boolean;
  type: CustomerType;
  status?: {
    in_store_status?: string;
    is_active?: boolean;
    missing_details?: boolean;
    updated_by?: number;
  };
  journals?: JournalNotes[];
  country?: string;
  deleted_at?: string;
  dl_exp_date: string | null;
  dl_number: string | null;
  email: string;
  flat_discount_rate: number | null;
  id_expiration?: string | null;
  id_type: string;
  id: number | null;
  in_store_pending?: boolean;
  in_store_filled?: boolean;
  medical_customer: number;
  member_level_code: string | null;
  member_level_id: number | null;
  phone: string;
  rec_exp_date: string | null;
  rec_num: string | null;
  status_at?: string;
  updated_at?: string | null;
  balance?: {
    amount: number;
  };
  id_number: string;
  medical_card_number: string;
  medical_card_expiration: string;
  checkin_note: string | null;
  serving_number?: ServingNumber;
  documents?: Attachment[];
  caregiverAssignment?: Customer[];
  patientsAssignment?: Customer[];
  caregiver?: Profile | null;
  patient?: Profile | null;
  isCaregiver?: boolean;
  isPatient?: boolean;
  extendedLimits?: boolean;
  attachments?: Array<{
    attachment?: Attachment;
    attachment_file_name?: string;
    attachment_thumb_url?: string;
    attachment_updated_at?: string;
  }>;
  imageToDisplay?: File;
  purchasingBy?: Customer | null;
  currentPatient?: Customer | null;
  customer_id?: string | null;
  doctor_name?: string;
  doctor_id?: string | number | null;
  more_info_filled?: 0 | 1;
  city?: string | null;
  address?: string | null;
  gender?: string | null;
  zip?: string | null;
  note?: string | null;
  notes?: string | null;
}

export interface CustomerModel extends Customer {
  patients: PatientsToSave;
  conditions: ConditionsToSave[] | null;
}

export interface PatientsToSave {
  attach: string[];
  detach: string[];
  assigned?: string[];
}

export interface ConditionsToSave {
  condition_id: number;
}

export interface CustomerAvatar {
  size?: number;
  name?: string;
  id: number;
  avatar_file_name: string;
  avatar_file_size: number;
  avatar_content_type: string;
  avatar_variants: string;
  avatar_updated_at: string;
  avatar_medium_url: string;
  avatar_thumb_url: string;
  avatar_original_url: string;
}

export interface CustomerBasicInfo {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  birthday: string | null;
  phone: string;
  id_number: string;
  id_type: string;
  id_expiration: string | null;
  checkin_note: string | null;
  shows_checkin_note: boolean;
  avatar?: SelectImage | File | null;
  avatar_medium_url?: string;
}

export interface Attachment extends File {
  _destroy?: boolean;
  attachment?: Attachment;
  id?: number;
  url?: string;
  lastModifiedDate?: string;
  location: AttachmentLocation;
}

export interface AttachmentLocation {
  account_id: number;
  id: number;
  name: string;
}

export interface Patient {
  patient_id: number | null;
  _destroy?: boolean;
}

export interface Profile {
  medical_card_number: string | null;
  medical_card_expiration: string | null;
  conditions?: Conditions[] | null;
  newConditions?: string[];
  profile_type?: string;
  condition_profiles?: Conditions[] | null;
  _destroy?: boolean;
  patients?: Patient[];
  doctor_id?: string | number | null;
  caregiver?: string;
}

export interface CustomerLimitConfig {
  customer_id?: string | null;
  location_limit_type?: string | null;
  is_limit_extended?: 0 | 1;
  limit_config?: RuleConfig[];
}
export interface Conditions {
  id?: number;
  name?: string;
  conditions_id?: number;
  pivot?: { id: number };
  _destroy?: boolean;
}

export const defaultProfile: Profile = {
  profile_type: "",
  medical_card_number: "",
  medical_card_expiration: ""
};

export const defaultCustomer: Customer = {
  customer_id: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  checkin_note: null,
  birthday: "",
  phone: "",
  cellphone: "",
  cellphone_carrier: "",
  id_is_temporary: false,
  rec_num: "",
  rec_exp_date: "",
  dl_number: "",
  dl_exp_date: "",
  medical_customer: 0,
  flat_discount_rate: null,
  member_level_id: null,
  member_level_code: null,
  tax_exempt: false,
  id_type: "",
  id_number: "",
  id: null,
  medical_card_number: "",
  medical_card_expiration: "",
  shows_checkin_note: false,
  caregiver: null,
  patient: null,
  type: "REGISTERED"
};

export const defaultCustomerModel: CustomerModel = {
  ...defaultCustomer,
  patients: {
    attach: [],
    detach: []
  },
  conditions: null
};

export const basicInfoModel: CustomerBasicInfo = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  birthday: "",
  phone: "",
  id_number: "",
  id_type: "",
  id_expiration: "",
  checkin_note: null,
  shows_checkin_note: false
};

export interface ServingNumber {
  account_id: number;
  customer_id: number | null;
  location_id: number;
  prefix?: string;
  status?: string;
  number: number;
  updated_at?: string;
  created_at?: string;
  _id?: string;
  department?: string;
}

export interface ScannedCustomer {
  id_number: string;
  last_name: string;
  first_name: string;
  middle_name: string;
  gender: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  id_expiration: string;
  birthday: string;
  [key: string]: string;
}

export interface MandatoryMoreInfoModal {
  icon?: string;
  body?: { [key: string]: string };
  prompt?: string;
  question?: string;
  action?: string;
  bodyParams?: string;
  rejectBtnText?: string;
  resolveBtnText?: string;
  showRejectBtn?: boolean;
  showResolveBtn?: boolean;
}
export interface FileAttachment {
  id?: number;
  attachment?: Attachment;
  media_file_name?: string;
  media_thumb_url?: string;
  media_updated_at?: string;
  location: AttachmentLocation;
}
