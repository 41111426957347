import { Customer } from "@/interfaces/customer";
import { customerService } from "@/services/customer.service";
import debounce from "lodash/debounce";
import dropWhile from "lodash/dropWhile";
import find from "lodash/find";
import union from "lodash/union";
import xorBy from "lodash/xorBy";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./ModalPatients.template.vue";

interface ArrayCompare {
  patientsList: Customer[];
  patientsAssignment: Customer[];
  arrayToDelete: Customer[];
  arrayTemporal: Customer[];
}
/**
 * product basic info form component
 */
@Component({
  mixins: [Template]
})
export default class ModalPatientsComponent extends Vue {
  public selection: string = "";
  public isLoading: boolean = false;
  public patientsList: Customer[] = [];
  public search: string = "";
  @Prop({ required: true })
  private arraysCompare!: ArrayCompare;
  private searchPatient = debounce(async (context, val: string) => {
    if (!val || val.length > 2) {
      context.isLoading = true;
      await context.searchUserAction(val);
      context.isLoading = false;
    }
  }, 500);

  @Watch("search")
  public async searchData(val: string) {
    this.searchPatient(this, val);
  }

  public firstLetter(name: string) {
    return name.substring(0, 1);
  }

  public filterPatient(
    item: { first_name: string; id_number: string; last_name: string },
    queryText: string = ""
  ) {
    const textOne = item.first_name ? item.first_name.toLowerCase() : "";
    const textTwo = item.id_number ? item.id_number.toLowerCase() : "";
    const textThree = item.last_name ? item.last_name.toLowerCase() : "";
    const searchText =
      typeof queryText === "string" ? queryText.toLowerCase() : "";

    return (
      textOne.indexOf(searchText) > -1 ||
      textTwo.indexOf(searchText) > -1 ||
      textThree.indexOf(searchText) > -1
    );
  }

  public addUser(item: Customer | null) {
    if (
      item &&
      !!!find(this.arraysCompare.arrayTemporal, [
        "customer_id",
        item.customer_id
      ])
    ) {
      if (
        !!find(this.arraysCompare.patientsAssignment, [
          "customer_id",
          item.customer_id
        ])
      ) {
        this.arraysCompare.arrayToDelete = dropWhile(
          this.arraysCompare.arrayToDelete,
          ["customer_id", item.customer_id]
        );
      } else {
        this.arraysCompare.arrayTemporal.push(item);
      }
    } else if (item) {
      this.removeUser(item!);
    }
    setTimeout(() => {
      this.selection = "";
    }, 1);
  }
  public removeUser(item: Customer) {
    const patient = find(this.arraysCompare.arrayTemporal, [
      "customer_id",
      item.customer_id
    ]);
    this.arraysCompare.arrayTemporal = !!patient
      ? xorBy([patient], this.arraysCompare.arrayTemporal, "customer_id")
      : [];
  }
  public mounted() {
    this.loadPatients();
  }
  public searchUserAction(data: string) {
    this.loadPatients(data);
  }
  protected raise() {
    this.$emit("resolve", this.arraysCompare.arrayTemporal);
  }
  protected apply() {
    this.raise();
  }
  protected close() {
    this.$emit("resolve");
  }
  private async loadPatients(data: string = "") {
    if (typeof data === "string") {
      const list: Customer[] = await customerService.getPatients(data);
      this.patientsList = union(
        list
          ? xorBy(list, this.arraysCompare.patientsAssignment, "customer_id")
          : [],
        this.arraysCompare.arrayToDelete
      );
    }
  }
}
