import { policyList } from "@/enums/permissions";
import { currencyFilter } from "@/filters/currency.filter";
import { fnsFormatToLocalDatetime } from "@/filters/date-fns.filter";
import { Customer } from "@/interfaces/customer";
import {
  LoyaltyProgram,
  LoyaltyProgramTier
} from "@/interfaces/loyaltyProgram";
import { CustomerProfileMetadata } from "@/metadata/customer";
import { i18n } from "@/plugins/i18n";
import { customerService } from "@/services/customer.service";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import { messagesService } from "@/services/messages.service";
import { BooleanCheck } from "@/types/types";
import {
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import sortBy from "lodash/sortBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AddRemovePopup from "./addRemovePoints/AddRemovePoints.component";
import Template from "./Loyalty.template.vue";

export interface TransactionEvent {
  type: string;
  points: number;
  total_points: number;
  amount_associated: number;
}

@Component({
  mixins: [Template],
  components: { TableComponent },
  filters: {
    currencyFilter,
    fnsFormatToLocalDatetime
  }
})
export default class LoyaltyComponent extends Vue {
  @Prop()
  public customer!: Customer;
  public amountStrings: {
    NUMBER_OF_VISITS: string;
    LOYALTY_POINTS: string;
  } = {
    NUMBER_OF_VISITS: "",
    LOYALTY_POINTS: ""
  };
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Prop({ required: true })
  public disableEditMode!: boolean;
  public isEditVisible = false;
  public transactionLog: TransactionEvent[] = [];
  public customerRewards: any = [];
  public customerPointsLog: TransactionEvent[] = [];
  public isLoading = false;
  public pagination: TablePagination = { ...TablePaginationDefault };
  public tableHeaders: TableHeader[] = CustomerProfileMetadata;
  public async changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.isLoading = true;
    await this.getLog();
    this.isLoading = false;
  }

  public async getLog() {
    try {
      const response = await loyaltyProgramService.getTransactionLog(
        this.customer.customer_id!,
        this.pagination
      );
      this.transactionLog = response.data;
      this.pagination = {
        currentPage: response.current_page,
        itemsPerPage: response.per_page,
        itemsPerPageOptions: [5, 10, 20, 50],
        totalItems: response.total,
        from: response.from,
        to: response.to
      } as TablePagination;

      this.customerPointsLog = this.transactionLog.filter(
        (event: TransactionEvent) => {
          return event.type === "LOYALTY_POINTS";
        }
      );
      this.customerPointsLog.map((el: any) => {
        if (el.user) {
          el.user.full_name = el.user.first_name + " " + el.user.last_name;
        }
        el.action_description = i18n.t(el.action).toString();
        return el;
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public get isNewCustomer() {
    return this.$route.name === "customers-add";
  }

  public async getRewards() {
    try {
      this.customerRewards = await customerService.getLoyaltyRewards(
        this.customer.customer_id!
      );
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public getCurrentRewards(program: LoyaltyProgram) {
    return (
      program.program_tiers &&
      program.program_tiers.filter((tier: LoyaltyProgramTier) => {
        return tier.apply;
      })
    );
  }

  public getNextRewards(program: LoyaltyProgram) {
    const currentPoints = this.customerRewards.total_points[
      program.program_based_on
    ];
    return (
      program.program_tiers &&
      sortBy(
        program.program_tiers.filter((tier: LoyaltyProgramTier) => {
          tier.remaining = tier.based_on_value - currentPoints;
          return tier.based_on_value > currentPoints;
        }),
        ["remaining", "price"]
      )
    );
  }

  public async openAddModal() {
    try {
      await this.$modals.load(
        AddRemovePopup,
        {
          size: "normal",
          positionX: "center",
          positionY: "center"
        },
        {
          modalData: {
            title: "customer.modify_loyalty_points",
            body: {
              points: this.customerRewards,
              customerUID: this.customer.customer_id
            }
          }
        }
      );
      Promise.all([this.getRewards(), this.getLog()]);
    } catch (e) {
      // avoid uncaught rejection.
    }
  }

  public mounted() {
    this.isEditVisible = this.hasPermission(
      policyList.modifyCustomerLoyaltyPoints
    );
    this.amountStrings = {
      NUMBER_OF_VISITS: this.$t("customer.visits").toString(),
      LOYALTY_POINTS: this.$t("customer.points").toString()
    };
    if (this.customer.customer_id) {
      this.isLoading = true;
      this.getLog();
      this.getRewards();
      this.isLoading = false;
    }
  }
}
